(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("./Dom"), require("html-entities"), require("json-diff-ts"));
	else if(typeof define === 'function' && define.amd)
		define(["./Dom", "html-entities", "json-diff-ts"], factory);
	else if(typeof exports === 'object')
		exports["FScripts"] = factory(require("./Dom"), require("html-entities"), require("json-diff-ts"));
	else
		root["FScripts"] = root["FScripts"] || {}, root["FScripts"]["client"] = factory(root["./Dom"], root["html-entities"], root["json-diff-ts"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__Dom__, __WEBPACK_EXTERNAL_MODULE_html_entities__, __WEBPACK_EXTERNAL_MODULE_json_diff_ts__) => {
return 